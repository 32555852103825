import React, { useState } from "react";
import * as s from "./ImageWithCaption.module.scss";
import DOMPurify from "isomorphic-dompurify";
import Fade from "react-reveal/Fade";
import { useScrollPercentage } from "react-scroll-percentage";

const ImageWithCaption = ({ content: { desktopImage, mobileImage, captionTitle, captionBody } }) => {
    const [revealed, setRevealed] = useState(false);
    const [ref, percentage] = useScrollPercentage({
        threshold: 0,
    });

    return (
        <div className={s.ImageWithCaption}>
            <div className={s.ImageWithCaption__image} ref={ref}>
                <Fade
                    bottom
                    distance={"40px"}
                    duration={700}
                    when={revealed || percentage.toPrecision(2) > 0.1}
                    onReveal={() => setRevealed(true)}
                >
                    <img
                        src={desktopImage?.localFile.publicURL}
                        alt={desktopImage?.altText}
                        className="d-none d-md-block"
                    />
                    <img
                        src={mobileImage?.localFile.publicURL}
                        alt={mobileImage?.altText}
                        className="d-block d-md-none"
                        style={{ width: 100 + "%" }}
                    />
                </Fade>
            </div>

            <div className={`small-body-copy ${s.ImageWithCaption__caption}`}>
                <h4
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(captionTitle),
                    }}
                />
                <div
                    className={s.ImageWithCaption__caption__captionBody}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(captionBody),
                    }}
                />
            </div>
        </div>
    );
};

export default ImageWithCaption;
