import React from "react";
import { Container } from "react-bootstrap";
import { usePublicationsQuery } from "../../query/PublicationsQuery";
import { Listings } from "../Listings/Listings";
import * as s from "./Publications.module.scss";

function Publications() {
    const {
        allWpPost: { nodes: allPublications },
    } = usePublicationsQuery();
    return (

        <Container className={s.section}>
            <div className="listing__wrapper">
                {allPublications.map((pubs) => {
                    const {
                        id,
                        title,
                        acfNewsMedia: { authors, date, link, source },

                    } = pubs;
                    return (
                        <Listings
                            id={id}
                            key={id}
                            date={date}
                            source={source}
                            title={title}
                            link={link}
                            authors={authors}
                        />
                    );
                })}
            </div>
        </Container>

    );
}

export default Publications;
