import { graphql, useStaticQuery } from "gatsby";

/**
 * The ID of the publications category is 163
 * This is hard coded in this query
 */
export const usePublicationsQuery = () => {
    return useStaticQuery(graphql`
        query GetAllPublications {
            allWpPost(filter: { categories: { nodes: { elemMatch: { databaseId: { eq: 163 } } } } }, sort:{fields: date, order:DESC} ) {
                nodes {
                    id
                    title
                    date
                    acfNewsMedia {
                        authors
                        source
                        date
                        link {
                            target
                            url
                        }
                    }
                }
            }
        }
    `);
};
