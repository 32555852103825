import React from "react";
import { Seo } from "../components/Helpers/Seo";
import Layout from "../components/Layout/Layout";
import Header from "../components/Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import ClickableContentNavigator from "../components/ClickableContentNavigator/ClickableContentNavigator";
import SectionDivider from "../components/SectionDivider/SectionDivider";
import CallToAction from "../components/CallToAction/CallToAction";
import * as styles from "../styles/pages/our-science.module.scss";
import { graphql } from "gatsby";
import DOMPurify from "isomorphic-dompurify";
import ImageWithCaption from "../components/ImageWithCaption/ImageWithCaption";
import Publications from "../components/Publications/Publications";
import Fade from "react-reveal/Fade";

// call out seo properties here
export const Head = ({ data }) => (
    <Seo title={data.wpPage.seo.title} description={data.wpPage.seo.metaDesc} keywords={data.wpPage.seo.metaKeywords} />
);

// call out seo properties here

const SciencePage = ({ data }) => {
    const acfCta = data.wpPage.ctaGlobal;
    const sectionHero = data.wpPage.acfSciencePage.scienceHeroSection;
    const section2 = data.wpPage.acfSciencePage.scienceSection2;
    const section3 = data.wpPage.acfSciencePage.scienceSection3;
    const section4 = data.wpPage.acfSciencePage.scienceSection4;
    const publicationsSection = data.wpPage.acfSciencePage.publicationsSection;
    const clickableContent = data.wpPage.clickableContentComponent.clickableContentComponent;
    const imageWithCaption = data.wpPage.imageWithCaption.imageWithCaption;

    return (
        <Layout>
            <Container fluid className="ellipse-background">
                <Header />
                <Container>
                    <Row className="content-bottom-pad">
                        <Col xs={12} lg={6} className="text-center">
                            <Fade bottom distance={"40px"} duration={700}>
                                <img
                                    src={sectionHero.desktopImage.localFile.publicURL}
                                    alt={sectionHero.desktopImage.altText}
                                    className={`${styles.headerImage} d-none d-md-block`}
                                />
                                <img
                                    src={sectionHero?.mobileImage?.localFile.publicURL}
                                    alt={sectionHero?.mobileImage?.altText}
                                    className={`${styles.headerImage} d-block d-md-none`}
                                />
                            </Fade>
                        </Col>
                        <Col xs={12} lg={6} className="ellipse-intro-text">
                            <div>
                                <h1
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(sectionHero?.title),
                                    }}
                                />
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(sectionHero?.bodyText),
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="content-bottom-pad">
                        <Col xs={12} lg={6} style={{ paddingBottom: 60 + "px" }}>
                            <h2
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(section2?.title),
                                }}
                            />
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(section2?.bodyText),
                                }}
                            />
                        </Col>
                        <Col xs={12} lg={6} className={styles.col__ImageWithCaption}>
                            <ImageWithCaption content={imageWithCaption} />
                        </Col>
                    </Row>
                </Container>
                <SectionDivider />
            </Container>

            <Container fluid style={{ paddingTop: 90 + "px" }}>
                <Container>
                    <Row>
                        <Col xs={12} lg={6}>
                            <h2
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(section3?.title),
                                }}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(section3?.bodyText),
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <ClickableContentNavigator
                            items={clickableContent.componentItems}
                            heading={clickableContent.componentTitle}
                        />
                    </Row>
                </Container>
            </Container>
            <Container fluid className="bg-alabaster section-top-padding">
                <Container className="section-bottom-pad">
                    <Row>
                        <Col xs={12} lg={6}>
                            <h2
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(section4?.title),
                                }}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(section4?.bodyText),
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
                <SectionDivider />
            </Container>
            <Container style={{ paddingTop: 90 + "px" }}>
                <h2 className={styles.publicationHeading}>{publicationsSection.heading}</h2>
            </Container>
            <Publications />

            <CallToAction content={acfCta} />
        </Layout>
    );
};

export default SciencePage;

export const pageQuery = graphql`
    query {
        wpPage(databaseId: { eq: 11 }) {
            seo {
                metaDesc
                title
                metaKeywords
            }
            acfSciencePage {
                scienceHeroSection {
                    title
                    bodyText
                    desktopImage {
                        localFile {
                            publicURL
                        }
                        altText
                    }
                    mobileImage {
                        localFile {
                            publicURL
                        }
                        altText
                    }
                }
                scienceSection2 {
                    title
                    bodyText
                }
                scienceSection3 {
                    title
                    bodyText
                }
                scienceSection4 {
                    title
                    bodyText
                }
                publicationsSection {
                    heading
                }
            }
            clickableContentComponent {
                clickableContentComponent {
                    componentTitle
                    componentItems {
                        title
                        body
                        desktopImage {
                            localFile {
                                publicURL
                            }
                            altText
                        }
                        mobileImage {
                            localFile {
                                publicURL
                            }
                            altText
                        }
                    }
                }
            }
            imageWithCaption {
                imageWithCaption {
                    desktopImage {
                        localFile {
                            publicURL
                        }
                        altText
                    }
                    mobileImage {
                        localFile {
                            publicURL
                        }
                        altText
                    }
                    captionTitle
                    captionBody
                }
            }
            ctaGlobal {
                description
                title
                link {
                    url
                    title
                    target
                }
            }
        }
    }
`;
